import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';

const NewSidebar = ({ routes, openSidebar }) => {
  return (
    <Box
      as="nav"
      pos="fixed"
      left={0}
      top={0}
      w={openSidebar ? '250px' : '88px'}
      h="100vh"
      bg="gray.800"
      color="white"
      transition="width 0.2s ease"
      overflowY="auto" // Enable vertical scrolling
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'gray.600',
          borderRadius: '24px',
        },
      }}
    >
      <VStack spacing={4} align="start" p={4}>
        {routes.length === 0 ? (
          <Text color="gray.400">No available routes</Text>
        ) : (
          routes.map((route, key) => (
            <NavLink to={route.path} key={key} style={{ width: '100%' }}>
              <Flex
                align="center"
                p={3}
                borderRadius="md"
                _hover={{ bg: 'gray.700' }}
                transition="background-color 0.2s ease"
              >
                {/* Render the icon from the route configuration */}
                {route.icon && (
                  <Box mr={openSidebar ? 3 : 0}>
                    {route.icon}
                  </Box>
                )}
                {/* Route name, only shown when sidebar is open */}
                {openSidebar && (
                  <Text fontSize="md" fontWeight="medium">
                    {route.name}
                  </Text>
                )}
              </Flex>
            </NavLink>
          ))
        )}
      </VStack>
    </Box>
  );
};

export default NewSidebar;

// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { Box, Flex, Text, VStack } from '@chakra-ui/react';

// const NewSidebar = ({ routes, openSidebar }) => {
//   return (
//     <Box
//       as="nav"
//       pos="fixed"
//       left={0}
//       top={0}
//       w={openSidebar ? '250px' : '88px'}
//       h="100vh"
//       bg="gray.800"
//       color="white"
//       transition="width 0.2s ease"
//       overflowY="auto" // Enable vertical scrolling
//       css={{
//         '&::-webkit-scrollbar': {
//           width: '6px',
//         },
//         '&::-webkit-scrollbar-thumb': {
//           background: 'gray.600',
//           borderRadius: '24px',
//         },
//       }}
//     >
//       <VStack spacing={4} align="start" p={4}>
//         {routes.map((route, key) => (
//           <NavLink to={route.path} key={key} style={{ width: '100%' }}>
//             <Flex
//               align="center"
//               p={3}
//               borderRadius="md"
//               _hover={{ bg: 'gray.700' }}
//               transition="background-color 0.2s ease"
//             >
//               {/* Render the icon from the route configuration */}
//               {route.icon && (
//                 <Box mr={openSidebar ? 3 : 0}>
//                   {route.icon}
//                 </Box>
//               )}
//               {/* Route name, only shown when sidebar is open */}
//               {openSidebar && (
//                 <Text fontSize="md" fontWeight="medium">
//                   {route.name}
//                 </Text>
//               )}
//             </Flex>
//           </NavLink>
//         ))}
//       </VStack>
//     </Box>
//   );
// };

// export default NewSidebar;
